'use client';

import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

export default function Component() {
  const query = useSearchParams();

  useEffect(() => {
    const queryObj = Object.fromEntries(query || []);

    const oldUtmJson = sessionStorage.getItem('utm_data') || '{}';
    const oldUtm = JSON.parse(oldUtmJson);

    sessionStorage.setItem('utm_data', JSON.stringify({ ...oldUtm, ...queryObj}));
  }, [query]);

  return null;
}
