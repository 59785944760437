import { atom } from 'recoil';

import { LeadFormType } from '@/app/_components/modals/enquire-forms/enquiry-forms/enquiry-forms';
import { SpecialDetails } from '@/services/SpecialsService.service';
import { NewCarData } from '@/types/new-cars/new-cars.types';
import { Vehicle } from '@/types/used-cars/used-cars.types';

export interface NewCarItem {
  type: 'new-car';
  newCar: NewCarData;
  mmCode?: string;
}

export interface UsedCarItem {
  type: 'used-car';
  usedCar: Vehicle;
}

export interface SpecialItem {
  type: 'special';
  special: SpecialDetails;
  leadType: LeadFormType;
}

export const drawerState = atom<boolean>({
  key: 'drawerState',
  default: false,
});

export const floatingCTAShowState = atom<boolean>({
  key: 'floatingCTAShowState',
  default: true,
});

export const formConfigState = atom<{
  formName: string;
  leadType: LeadFormType;
}>({
  key: 'floatingCTAConfigState',
  default: { formName: 'General', leadType: 'general' },
});

export const formItemState = atom<
  UsedCarItem | NewCarItem | SpecialItem | undefined
>({
  key: 'floatingCTAItemState',
  default: undefined,
});
