'use client'

import { ImageLoaderProps } from "next/image";

/**
 * Instead of modifying and serving images from our node server, use this image loader
 * to do the transformation on Directus and serve the images from the CDN
 * */
export function directusImageLoader({ src, width, quality }: ImageLoaderProps) {
  // if not a Directus asset, return src as is
  const directusAssetsURL = process.env.NEXT_PUBLIC_ASSETS_URL;

  if (!directusAssetsURL || src.indexOf(directusAssetsURL) < 0) {
    return src
  }

  const keyParamIndex = src.indexOf('?key=');

  if (keyParamIndex > 0) {
    const key = src.substring(keyParamIndex+5);
    const size = /image-(\d+)-(\d+).*/.exec(key);

    if (size) {
      const w = parseInt(size[1]);
      const h = parseInt(size[2]);

      // check if the requested image size is larger than the size in the key, then request double the resolution
      if (width > w) {
        src = src.replace(`image-${w}-${h}`, `image-${2*w}-${2*h}`);
      }
    }

    // appending an extra unused parameter to prevent nextjs warning when not using the width parameter
    return src += `&_requested_width=${width}`;
  }

  // we need to request the correct size image, Directus will resize it for us on the fly
  // this should be avoided and we need to use transformation keys.
  console.warn("Requested to load an image from Directus without a key: ", src, width);
  quality = quality || 90;

  if (src.indexOf('?') > 0) {
    return `${src}&fit=cover&width=${width}&quality=${quality}&withoutEnlargement=true&format=webp`;
  }
  return `${src}?fit=cover&width=${width}&quality=${quality}&withoutEnlargement=true&format=webp`;
}
