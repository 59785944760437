'use client';

import { Button } from '@chakra-ui/button';
import { Box, Text } from '@chakra-ui/layout';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/modal';
import dynamic from 'next/dynamic';
import React, { useCallback, useState } from 'react';

import { ReactComponent as CloseBlackIcon } from '@/app/_icons/CkClose_black.svg';
import { ReactComponent as SearchWhiteIcon } from '@/app/_icons/CkSearchWhite.svg';
import { BlogItem } from '@/types/blog/blog.types';
import { NewCar } from '@/types/new-cars/new-cars.types';

import './GlobalSearch.scss';

const InstantSearch = dynamic(() => import('./InstantSearch'));

interface Props {
  config: {
    retail_price_list: string;
    search_quick_links: {
      title: string;
      url: string;
    }[];
  };
  newCars: NewCar[];
  latestBlogPosts: BlogItem[];
}

const GlobalSearch = ({ config, newCars, latestBlogPosts }: Props) => {
  const [hasSearched, setHasSearched] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleSelectItem = () => {
    setIsDrawerOpen(false);
  };

  const queryHook = useCallback(
    (query: string, searchFn: (query: string) => any) => {
      if (query && query.length) {
        searchFn(query);
        setHasSearched(true);
      } else {
        setHasSearched(false);
      }
    },
    [setHasSearched],
  );

  return (
    <>
      <Box className="headerGlobalSearchBtnDesk">
        <Button
          onClick={() => {
            setIsDrawerOpen(true);
            setHasSearched(false);
          }}
          variant="solid"
          borderRadius={0}
          className="searchDrawerBtn"
        >
          <SearchWhiteIcon className="searchIcon" />
          Search
        </Button>
      </Box>
      <Box className="headerGlobalSearchBtnMob">
        <Button
          onClick={() => {
            setIsDrawerOpen(true);
            setHasSearched(false);
          }}
          variant="solid"
          borderRadius={0}
          className="searchDrawerBtn"
        >
          <SearchWhiteIcon className="searchIcon" />
        </Button>
      </Box>
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        preserveScrollBarGap
        size="md"
        id="globalSearchWrap"
      >
        <DrawerOverlay />
        <DrawerContent>
          <Button
            variant="solid"
            onClick={() => {
              setIsDrawerOpen(false);
              setHasSearched(false);
            }}
            borderRadius={0}
            className="searchDrawerCloseBtn"
          >
            <CloseBlackIcon className="closeIcon" />
            <Text className="hideMobileClose">Close</Text>
          </Button>
          <DrawerBody
            paddingX={{ base: 6, sm: 10 }}
            paddingTop={{ base: 0 }}
            paddingBottom={{ base: 6, sm: 8 }}
          >
            <Box>
              <InstantSearch
                hasSearched={hasSearched}
                onSelectItem={handleSelectItem}
                queryHook={queryHook}
                config={config}
                newCars={newCars}
                latestBlogPosts={latestBlogPosts}
              />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default GlobalSearch;
