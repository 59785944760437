'use client';
import { useDisclosure } from '@chakra-ui/hooks';
import { Text } from '@chakra-ui/layout';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/modal';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import slugify from 'react-slugify';

import { ReactComponent as CloseIcon } from '@/app/_icons/CkClose.svg';
import { ReactComponent as BurgerIcon } from '@/app/_icons/CkHamburger.svg';
import { ReactComponent as LocationIcon } from '@/app/_icons/CkLocationIcon16.svg';
import { ReactComponent as KiaLogo } from '@/app/_icons/kia-logo-white.svg';
import { NewCar } from '@/types/new-cars/new-cars.types';

import './Hamburger.scss';
import { SubMenu } from './SubMenu';

export const Hamburger = ({
  newCars,
  selectedDealershipName,
  config,
}: {
  newCars: NewCar[];
  selectedDealershipName?: string;
  config: {
    retail_price_list: string;
    search_quick_links: {
      title: string;
      url: string;
    }[];
  };
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const pathname = usePathname();

  useEffect(() => {
    onClose();
  }, [pathname]);

  return (
    <>
      {!isOpen ? (
        <button
          role="button"
          name="Hamburger menu"
          className="hamburgerIcon"
          onClick={onOpen}
        >
          <BurgerIcon />
        </button>
      ) : (
        <button
          role="button"
          name="Close menu"
          className="MenuCloseIcon"
          onClick={onClose}
        >
          <CloseIcon />
        </button>
      )}

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        preserveScrollBarGap
        size="full"
        variant="clickThrough"
        id="mobileDrawer"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton
            className="mobileDrawerClose"
            color={'white'}
            top={6}
            right={5}
            role="button"
            name="closeHamburger"
          />
          <DrawerHeader border={0} paddingY={7} className="mobileDrawerHeader">
            {selectedDealershipName ? (
              <>
                <Link
                  role="link"
                  className="logo"
                  href={`/dealerships/${slugify(selectedDealershipName)}`}
                >
                  <KiaLogo className="KiaLogo" width={'80px'} height={'20px'} />
                  <Text fontSize="xs" color={'white'} marginTop={2}>
                    {selectedDealershipName}
                  </Text>
                </Link>
              </>
            ) : (
              <Link role="link" className="logo" href="/">
                <KiaLogo className="KiaLogo" width={'80px'} height={'20px'} />
              </Link>
            )}
          </DrawerHeader>
          <DrawerBody className="mobileDrawerBody" border={0}>
            <SubMenu
              closeHamburger={onClose}
              newCars={newCars}
              selectedDealershipName={selectedDealershipName}
              text="Vehicles"
            />
            <SubMenu
              closeHamburger={onClose}
              config={config}
              text="Shopping Tools"
            />
            <SubMenu
              closeHamburger={onClose}
              text="Promotions"
              url="/promotions"
            />
            <SubMenu
              closeHamburger={onClose}
              text="Experience Kia"
              url="/blog"
            />
            <SubMenu closeHamburger={onClose} text="Financial Services" />
            {!selectedDealershipName ? (
              <SubMenu
                closeHamburger={onClose}
                text="Find a dealer"
                icon={<LocationIcon />}
                url="/find-a-dealer"
              />
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
