'use client';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { destroyCookie } from 'nookies';
import React, { MouseEventHandler } from 'react';

import { ReactComponent as HeartIcon } from '@/app/_icons/heart-icon24px.svg';
import { ReactComponent as KiaLogo } from '@/app/_icons/kia-logo-white.svg';
import { COOKIE_NAME_DEALERSHIP_ID } from '@/constants/dealership/dealership.constants';

import styles from './MainOffers.module.scss';

type MainOffersProps = {
  offerType: string;
  id: number;
  link: string;
  props?:
    | {
        'data-cy'?: string;
      }
    | undefined;
}[];
function MainOffers({
  mainOffers,
  dealershipId,
}: {
  mainOffers: MainOffersProps;
  dealershipId: string | undefined;
}) {
  const router = useRouter();

  const handleReturnHome: MouseEventHandler = () => {
    destroyCookie(null, COOKIE_NAME_DEALERSHIP_ID, {
      path: '/',
    });
    router.refresh();
    router.push('/');
    return true;
  };

  return (
    <div className={styles.offersRowWrapper}>
      <div className={styles.footerLogo}>
        <a className={styles.offerList} href={'/'} onClick={handleReturnHome}>
          <KiaLogo width="80" height="20" />
        </a>
      </div>
      <div className={styles.offerList}>
        {dealershipId && (
          <a
            className={styles.returnToHome}
            href={'/'}
            onClick={handleReturnHome}
          >
            Go to Kia South Africa
          </a>
        )}
        <ul>
          {mainOffers.map((item) => (
            <li key={item.id}>
              {item.offerType === 'Find a Dealership' &&
              dealershipId ? null : item.offerType !== 'Why Kia' ? (
                <Link href={item.link} {...item.props}>
                  {item.offerType}
                </Link>
              ) : (
                <Link
                  href={item.link}
                  {...item.props}
                  className={styles.linkIcon}
                >
                  Why Kia <HeartIcon className={styles.heartIcon} />
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MainOffers;
